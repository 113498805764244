<template>
  <tr>
    <td :class="['px-6 py-4 whitespace-nowrap text-sm', !$v.candidateToEdit.firstName.required && !isEditing ? 'border border-red-500' : '']">
      <span
        v-if="!isEditing"
      >
        {{ candidate.firstName }}
      </span>
      <input
        v-if="isEditing"
        ref="firstInput"
        v-model.trim="$v.candidateToEdit.firstName.$model"
        type="text"
        :class="['form-input block w-full text-sm', inputColor]"
        autocomplete="given-name"
      />
    </td>

    <td :class="['px-6 py-4 whitespace-nowrap text-sm', !$v.candidateToEdit.surname.required && !isEditing ? 'border border-red-500' : '']">
      <span
        v-if="!isEditing"
      >
        {{ candidate.surname }}
      </span>
      <input
        v-if="isEditing"
        v-model.trim="$v.candidateToEdit.surname.$model"
        type="text"
        :class="['form-input block w-full text-sm', inputColor]"
        autocomplete="family-name"
      />
    </td>

    <td :class="['px-6 py-4 whitespace-nowrap text-sm', emailInvalid && !isEditing ? 'border border-red-500' : '']">
      <span
        v-if="!isEditing"
      >
        {{ candidate.email }}
      </span>
      <input
        v-if="isEditing"
        v-model.trim="$v.candidateToEdit.email.$model"
        type="email"
        :class="['form-input block w-full text-sm', inputColor]"
        autocomplete="email"
      />
    </td>

    <td class="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
      <a
        v-if="!isEditing"
        href="javascript:;"
        class="hover:text-secondary-400 duration-150 focus:outline-none"
        @click="editRow()"
      >
        <span class="hidden sm:inline-block">Edit</span>

        <Icon
          view-box="0 0 24 24"
          class="w-4 h-4 ml-1"
        >
          <Edit />
        </Icon>
      </a>

      <a
        v-else
        href="javascript:;"
        class="text-secondary hover:text-secondary-400 duration-150 focus:outline-none"
        @click="submitRow()"
      >
        <span>Done</span>
      </a>
    </td>

    <td class="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
      <a
        href="javascript:;"
        class="text-red-500 hover:text-red-400 duration-150 focus:outline-none"
        @click="deleteRow()"
      >
        <span class="hidden sm:inline-block">Delete</span>

        <Icon
          view-box="0 0 24 24"
          class="w-4 h-4 ml-1"
        >
          <Bin />
        </Icon>
      </a>
    </td>
  </tr>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Edit from '@components/Icons/Edit'
import Bin from '@components/Icons/Bin'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    Icon,
    Edit,
    Bin
  },

  mixins: [validationMixin],

  props: {
    candidate: {
      type: Object,
      default: null
    },

    rowIndex: {
      type: Number,
      default: 0
    },

    rowsEditing: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      candidateToEdit: Object.assign({}, this.candidate)
    }
  },

  validations: {
    candidateToEdit: {
      firstName: {
        required
      },
      surname: {
        required
      },
      email: {
        required,
        email
      }
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    isEditing() {
      return this.rowsEditing.includes(this.rowIndex)
    },

    /**
     * @return {Boolean}
     */
    isEvenRow() {
      return this.rowIndex % 2 === 0
    },

    /**
     * @return {String}
     */
    inputColor() {
      if (this.isEvenRow) {
        return 'bg-gray-100'
      } else {
        return 'bg-white'
      }
    },

    /**
     * @return {Boolean}
     */
    emailInvalid() {
      return !this.$v.candidateToEdit.email.email
    }
  },

  methods: {
    editRow() {
      this.$emit('editing', this.rowIndex)
      this.$nextTick(() => {
        this.$refs.firstInput.focus()
      })
    },

    deleteRow() {
      this.$emit('delete-row', this.rowIndex)
    },

    submitRow() {
      this.$emit('submit-row', { index: this.rowIndex, candidate: this.candidateToEdit })
    }
  }
}
</script>
