<template>
  <div
    ref="lavContainer"
    :style="style"
  ></div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  props: {
    options: {
      type: Object,
      required: true
    },
    height: Number,
    width: Number
  },

  data() {
    return {
      style: {
        overflow: 'hidden',
        margin: '0 auto'
      }
    }
  },

  created() {
    lottie.setQuality('low')
  },

  beforeDestroy() {
    lottie.stop()
    lottie.destroy()
  },

  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lavContainer,
      renderer: 'svg',
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: JSON.parse(this.options.animationData),
      rendererSettings: this.options.rendererSettings
    })
    this.$emit('animCreated', this.anim)

    setTimeout(() => {
      this.$emit('onComplete')
    }, 1500)
  }
}
</script>
