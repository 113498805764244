<template>
  <div>
    <a
      href="javascript:;"
      class="text-secondary hover:text-green-500 duration-150 ease-out text-sm font-medium"
      @click="modalOpen = true"
    >
      Import spreadsheet
    </a>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Import spreadsheet
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <UploadCSVForm
        @animationComplete="modalOpen = false"
        @uploadedCandidates="$emit('uploadedCandidates', $event)"
      />
    </Modal>
  </div>
</template>

<script>
import UploadCSVForm from '@components/Jobs/UploadCSVForm'
import Icon from '@components/Icons/Icon'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'
// import Upload from '@components/Icons/Upload'

export default {
  components: {
    UploadCSVForm,
    Icon,
    Modal,
    Plus
    // Upload
  },

  data() {
    return {
      modalOpen: false
    }
  }
}
</script>
