<template>
  <form
    class="px-6 pt-6 pb-8 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <ul class="list-disc list-inside text-sm">
      <li>Use the example below to format your spreadsheet.</li>
      <li>Other data in the spreadsheet will be ingored.</li>
      <li>You don't need to use column headings.</li>
    </ul>

    <table class="min-w-full divide-y divide-gray-300 border border-gray-300 bg-white text-gray-600 my-6">
      <tbody>
        <tr
          v-for="(candidate, index) in dummyCSV"
          :key="index"
        >
          <td class="px-2 py-1 text-left text-sm border border-right border-gray-300">
            {{ candidate.firstName }}
          </td>
          <td class="px-2 py-1 text-left text-sm border border-right border-gray-300">
            {{ candidate.surname }}
          </td>
          <td class="px-2 py-1 text-left text-sm border border-right border-gray-300">
            {{ candidate.email }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="relative">
      <template v-if="!submitSuccess">
        <label
          v-if="!chosenFile"
          for="csv-upload"
          class="focus:outline-none inline-flex font-medium leading-tight shadow-md duration-150 rounded bg-secondary hover:bg-secondary-400 text-white px-4 py-3 stroke-current cursor-pointer"
        >
          <span>Choose file</span>

          <input
            id="csv-upload"
            ref="chooseFile"
            name="csv-upload"
            type="file"
            class="sr-only"
            @change="addFile()"
            @click="errors = []"
          >
        </label>

        <div class="flex items-center">
          <BaseButton
            v-if="chosenFile"
            :loading="loading"
            :disabled="loading"
            @click="uploadFile"
          >
            <span>Add candidates</span>
            <template slot="iconRight">
              <Icon
                view-box="0 0 24 24"
                class="w-6 h-6 ml-2 text-white fill-none"
              >
                <Upload />
              </Icon>
            </template>
          </BaseButton>

          <div
            v-if="chosenFile"
            class="text-gray-700 text-sm ml-2 flex font-mono"
          >
            <p class="mr-1 max-w-xl truncate">
              {{ chosenFile.name }}
            </p>
            <a
              href="javascript:;"
              @click="removeFile"
            >✕ <span class="sr-only">Remove file</span></a>
          </div>
        </div>

        <template v-if="errors.length">
          <ErrorsInline
            v-for="(error, index) in errors"
            :key="index"
            class="relative mt-2"
          >
            {{ error }}
          </ErrorsInline>
        </template>

        <p class="text-xs text-gray-700 mt-1">
          Accepted formats: .csv, .xls, .xlsx
        </p>
      </template>

      <Lottie
        v-if="submitSuccess"
        :options="successAnimation"
        class="w-16 h-16 top-0 left-0"
        @onComplete="$emit('animationComplete')"
      />
    </div>
  </form>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Upload from '@components/Icons/Upload'
import Lottie from '@components/Lottie'
import success from '@data/successAnimation.json'
import ErrorsInline from '@components/ErrorsInline'
import jobsApi from '@api/jobs'

import { validationMixin } from 'vuelidate'

export default {
  components: {
    Icon,
    Upload,
    Lottie,
    ErrorsInline
  },

  mixins: [validationMixin],

  data() {
    return {
      loading: false,
      hasErrors: false,
      errors: [],

      chosenFile: null,

      // Animation
      submitSuccess: false,
      successAnimation: { animationData: JSON.stringify(success), loop: false },

      dummyCSV: [
        {
          firstName: 'Richard',
          surname: 'Briggs',
          email: 'richard@example.com'
        },
        {
          firstName: 'David',
          surname: 'Thomas',
          email: 'dt@example2.com'
        },
        {
          firstName: 'Katie',
          surname: 'Mansfield',
          email: 'kmansfield@example3.com'
        }
      ]
    }
  },

  methods: {
    addFile(event) {
      this.chosenFile = this.$refs.chooseFile.files[0]
    },

    removeFile() {
      this.chosenFile = null
    },

    uploadFile() {
      this.loading = true

      let formData = new FormData()
      formData.append('import', this.chosenFile)

      return jobsApi.parseSpreadSheet(formData)
        .then(response => {
          this.$emit('uploadedCandidates', response)
          this.loading = false
          this.submitSuccess = true
        })
        .catch(error => {
          this.loading = false
          this.errors.push(error)
          this.chosenFile = null
          console.error(error)
        })
    }
  }
}
</script>
